import React from 'react';

export const Card = (props) => {

	return (
		<svg
		width={ props.size }
		height={ props.size }
		viewBox="0 0 1240 1240"
		xmlns="http://www.w3.org/2000/svg" >
			<g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<path d="M591.00778,573.743164 L591.00778,598.514399 L894.50778,598.514399 C913.009319,598.514399 928.00778,613.512859 928.00778,632.014399 L928.00778,647.998036 C928.00778,666.499575 913.009319,681.498036 894.50778,681.498036 L591.00778,681.498036 L591.00778,706.269273 L827.633608,706.269271 C846.135147,706.269271 861.133608,721.267732 861.133608,739.769271 L861.133608,755.752908 C861.133608,774.254447 846.135147,789.252908 827.633608,789.252908 L591.00778,789.252908 L591.00778,814.024143 L729.799171,814.024143 C748.30071,814.024143 763.299171,829.022604 763.299171,847.524143 L763.299171,863.50778 C763.299171,882.009319 748.30071,897.00778 729.799171,897.00778 L396.792548,897.00778 C393.979171,897.00778 391.246795,896.660974 388.635838,896.00778 L479.714664,896.00778 C476.589871,896.668491 473.285466,897.00778 469.79586,897.00778 C442.019338,897.00778 411.303357,897.00778 371.961423,897.00778 C265.948353,897.00778 180.00778,787.489491 180.00778,652.391835 C180.00778,530.081463 250.449376,428.73734 342.453671,410.648243 L342.453683,410.648239 L556.159387,344.499154 C573.833598,339.028393 592.596305,348.921243 598.067066,366.595454 C598.090131,366.669968 598.112935,366.744563 598.135479,366.819237 L602.517093,381.33292 C607.83787,398.957492 597.939355,417.573057 580.35238,423.016816 L436.977274,467.396222 C441.266079,474.684488 445.50156,482.499459 449.658212,490.759526 L827.633608,490.759526 C846.135147,490.759526 861.133608,505.757987 861.133608,524.259526 L861.133608,540.243164 C861.133608,558.744703 846.135147,573.743164 827.633608,573.743164 L591.00778,573.743164 Z" id="Combined-Shape" fill={ props.color } transform="translate(554.007780, 620.000000) rotate(-90.000000) translate(-554.007780, -620.000000) "></path>
			</g>
		</svg>
	)
}

export default Card;
