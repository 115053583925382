import React from 'react';

export const Time = (props) => {

	return (
		<svg
		width={ props.size }
		height={ props.size }
		viewBox="0 0 1240 1240"
		xmlns="http://www.w3.org/2000/svg" >
			<g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<path d="M542.531227,715.392159 C499.626617,683.49195 471,626.054597 471,560.5 C471,494.945403 499.626617,437.50805 542.531227,405.607841 L322.886325,405.607843 C322.935876,406.578925 322.960929,407.555892 322.960929,408.538235 C322.960929,442.524864 292.972754,470.076471 255.980465,470.076471 C254.911246,470.076471 253.847879,470.053453 252.790919,470.007928 L252.790919,650.992072 C253.847879,650.946547 254.911246,650.923529 255.980465,650.923529 C292.972754,650.923529 322.960929,678.475136 322.960929,712.461765 C322.960929,713.444108 322.935876,714.421075 322.886325,715.392157 L542.531225,715.392157 Z M698.468775,715.392157 L916.291077,715.392157 C916.241526,714.421075 916.216473,713.444108 916.216473,712.461765 C916.216473,678.475136 946.204649,650.923529 983.196938,650.923529 C984.882927,650.923529 986.554367,650.980761 988.209081,651.093224 L988.209081,469.906776 C986.554367,470.019239 984.882927,470.076471 983.196938,470.076471 C946.204649,470.076471 916.216473,442.524864 916.216473,408.538235 C916.216473,407.555892 916.241526,406.578925 916.291077,405.607843 L698.468775,405.607843 C741.373383,437.50805 770,494.945403 770,560.5 C770,626.054597 741.373383,683.49195 698.468773,715.392159 Z M1052,715.392157 L1052,774 L189,774 L189,715.392157 L189,405.607843 L189,347 L1052,347 L1052,405.607843 L1052,715.392157 Z M189,864 L1052,864 L1052,899 L189,899 L189,864 Z M189,804 L1052,804 L1052,839 L189,839 L189,804 Z" id="Combined-Shape" fill={ props.color }></path>
			</g>
		</svg>
	)
}

export default Time;
