import React from 'react';

export const CloudUpload = (props) => {

	return (
		<svg
		width={ props.size }
		height={ props.size }
		viewBox="0 0 1240 1240"
		xmlns="http://www.w3.org/2000/svg" >
			<path d="M647.805422,792 L647.805422,558.213356 L780.26217,690.301315 C791.354606,701.181707 809.407004,701.181707 820.281942,690.083707 C831.15688,678.985707 830.939382,661.141864 819.629446,650.47908 L639.105472,471.170219 C633.668002,465.947631 626.708042,463.118729 619.313084,463.118729 C615.615605,463.118729 611.918126,463.771553 608.438146,465.294808 C604.958166,466.818063 602.130682,468.776533 599.520696,471.170219 L418.996722,650.47908 C407.904285,661.57708 407.904285,679.203315 418.779223,690.301315 C429.87166,701.181707 447.706559,701.399315 458.798996,690.518923 L591.255743,558.213356 L591.255743,792 L404.319958,792 C397.802969,792.661276 391.190939,793 384.5,793 C277.080616,793 190,705.695526 190,598 C190,512.754211 244.559708,440.284284 320.579424,413.775005 C320.195415,408.566978 320,403.306364 320,398 C320,281.467918 414.24406,187 530.5,187 C608.638737,187 676.833509,229.676305 713.152002,293.044508 C734.473934,278.502293 760.244058,270 788,270 C861.453872,270 921,329.546128 921,403 C921,418.208301 918.447384,432.820407 913.747219,446.431248 C991.221523,464.237511 1049,533.621312 1049,616.5 C1049,712.873689 970.873689,791 874.5,791 C874.333279,791 874.166612,790.999766 874,790.999299 L874,792 L647.805422,792 Z M647.805422,793 L647.805422,1061.54029 C647.805422,1076.99045 635.190494,1089.6117 619.530583,1089.6117 C603.870672,1089.6117 591.255743,1076.99045 591.255743,1061.54029 L591.255743,793.012089 L647.805422,793 Z" id="Combined-Shape" fill={ props.color }></path>
			<g id="Group" transform="translate(590.000000, 793.000000)">
					<g id="_ionicons_svg_ios-arrow-round-back" transform="translate(0.500000, 0.000000)"></g>
			</g>
		</svg>
	)
}

export default CloudUpload;
