import React from 'react';

export const Heart = (props) => {

	return (
		<svg
		width={ props.size }
		height={ props.size }
		viewBox="0 0 1240 1240"
		xmlns="http://www.w3.org/2000/svg" >
			<g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<path d="M295.079363,544.737482 C291.757896,530.699187 290,516.055162 290,501 C290,500.973294 290.000006,500.946588 290.000017,500.919884 C290.000006,500.894213 290,500.868546 290,500.84288 C290,499.70885 290.010799,498.582082 290.032397,497.462574 C291.915846,394.713103 375.683091,312 478.742718,312 C534.682103,312 584.937617,336.36871 619.5,375.083223 C654.062383,336.36871 704.317897,312 760.257282,312 C863.893976,312 948.021634,395.641976 948.991528,499.19044 C948.997176,499.766554 949,500.34455 949,500.924444 C948.999995,500.949628 949,500.974814 949,501 C949,515.486484 947.372398,529.592302 944.290319,543.144093 C921.167837,649.730591 812.904398,778.015892 619.5,928 C427.106857,778.752489 318.966646,650.998322 295.079367,544.737498 Z" id="Combined-Shape" fill={ props.color }></path>
			</g>
		</svg>
	)
}

export default Heart;
