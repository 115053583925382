import React from 'react';

export const Coffee = (props) => {

	return (
		<svg
		width={ props.size }
		height={ props.size }
		viewBox="0 0 1240 1240"
		xmlns="http://www.w3.org/2000/svg" >
			<g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<path d="M711,498 L885,498 C909.300529,498 929,517.699471 929,542 L929,718 C929,742.300529 909.300529,762 885,762 L789,762 L789,878 C789,933.228475 744.228475,978 689,978 L412,978 C356.771525,978 312,933.228475 312,878 L312,542 C312,517.699471 331.699471,498 356,498 L711,498 Z M789,567 L789,693 L849,693 C858.388841,693 866,685.388841 866,676 L866,584 C866,574.611159 858.388841,567 849,567 L789,567 Z M405,292 C424.329966,292 440,307.670034 440,327 L440,423 C440,442.329966 424.329966,458 405,458 C385.670034,458 370,442.329966 370,423 L370,327 C370,307.670034 385.670034,292 405,292 Z M545,262 C564.329966,262 580,277.670034 580,297 L580,418 C580,437.329966 564.329966,453 545,453 C525.670034,453 510,437.329966 510,418 L510,297 C510,277.670034 525.670034,262 545,262 Z M685,281 C704.329966,281 720,296.670034 720,316 L720,418 C720,437.329966 704.329966,453 685,453 C665.670034,453 650,437.329966 650,418 L650,316 C650,296.670034 665.670034,281 685,281 Z" id="Combined-Shape" fill={ props.color }></path>
			</g>
		</svg>
	)
}

export default Coffee;
