import React from 'react';

export const Code = (props) => {

	return (
		<svg
		width={ props.size }
		height={ props.size }
		viewBox="0 0 1240 1240"
		xmlns="http://www.w3.org/2000/svg" >
			<g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Artboard" fill={ props.color }>
						<g id="Group" transform="translate(256.000000, 404.000000)">
								<path d="M721.532581,197.801844 C731.75503,208.909573 731.479314,226.206178 720.705433,236.98006 L534.951738,422.733755 C523.894899,433.790594 505.968221,433.790594 494.911383,422.733755 C483.854544,411.676916 483.854544,393.750239 494.911383,382.6934 L660.796375,216.808408 L493.294567,49.3066 C482.237729,38.2497613 482.237729,20.3230838 493.294567,9.2662451 C504.351406,-1.79059359 522.278084,-1.79059359 533.334922,9.2662451 L719.088617,195.01994 C719.974202,195.905524 720.788856,196.835179 721.532581,197.801844 Z M7.46741931,197.801844 C8.21114395,196.835179 9.02579841,195.905524 9.91138268,195.01994 L195.665078,9.2662451 C206.721916,-1.79059359 224.648594,-1.79059359 235.705433,9.2662451 C246.762271,20.3230838 246.762271,38.2497613 235.705433,49.3066 L68.2036249,216.808408 L234.088617,382.6934 C245.145456,393.750239 245.145456,411.676916 234.088617,422.733755 C223.031779,433.790594 205.105101,433.790594 194.048262,422.733755 L8.29456745,236.98006 C-2.47931399,226.206178 -2.75503004,208.909573 7.46741931,197.801844 Z" id="Combined-Shape"></path>
						</g>
				</g>
			</g>
		</svg>
	)
}

export default Code;
