import React from 'react';

export const ArrowDown = (props) => {
	return (
		<svg
		width={ props.size }
		height={ props.size }
		viewBox="0 0 1240 1240"
		xmlns="http://www.w3.org/2000/svg" >
		    <g id="Artboard" stroke="none" strokeWidth="1" fill={ props.color } fillRule="evenodd">
					<g id="ArrowDown" transform="translate(620.500000, 620.500000) rotate(-90.000000) translate(-620.500000, -620.500000) translate(206.000000, 345.000000)">
	            <path d="M300.754255,63.3502311 L126.181139,238.107743 L791.399618,238.107743 C811.819208,238.107743 828.5,254.751316 828.5,275.412302 C828.5,296.073289 811.819208,312.716862 791.399618,312.716862 L126.181139,312.716862 L301.041855,487.474374 C315.421848,502.109239 315.134248,525.639807 300.754255,540.274673 C286.086662,554.62258 262.791073,554.62258 248.12348,539.987715 L11.1411949,301.812452 C7.97759639,298.368954 5.38919764,294.638498 3.37599861,290.047168 C1.36279958,285.455838 0.5,280.577549 0.5,275.699261 C0.5,265.942684 4.23879819,256.760023 11.1411949,249.586069 L248.12348,11.4108064 C262.215874,-3.51101734 285.799062,-3.79797549 300.466655,10.5499319 C315.134248,24.8978393 315.134248,48.7153656 300.754255,63.3502311 Z" id="Path"></path>
	        </g>
		    </g>
		</svg>
	)
}

export default ArrowDown;
