import React from 'react';

export const Card = (props) => {

	return (
		<svg
		width={ props.size }
		height={ props.size }
		viewBox="0 0 1240 1240"
		xmlns="http://www.w3.org/2000/svg" >
			<g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<path d="M559,926.947847 L559,926.997703 L324,926.997703 L324,619.997703 L420.128306,619.997703 C436.54537,573.703124 465.255961,536.030106 500.943381,513.76593 C520.313809,496.671842 532.784309,478.637343 538.354881,459.662434 C546.364373,432.379883 544.147318,401.512807 531.703713,367.061207 L531.703732,367.0612 C524.141279,346.123647 534.983939,323.019828 555.921492,315.457374 C559.443964,314.185089 563.126566,313.409935 566.863029,313.154281 C600.481289,310.854068 630.326228,334.519331 635.749241,367.776951 C642.052965,406.435865 645.853597,434.43093 647.15111,451.76215 C648.298988,467.094652 648.737568,490.77456 648.466848,522.801876 L847.656327,522.801876 C876.558911,522.801876 899.989084,546.232049 899.989084,575.134633 C899.989084,596.643352 887.01337,615.121431 868.462865,623.167947 C894.638165,626.051761 915,648.239709 915,675.183418 C915,702.068019 894.727404,724.217702 868.635046,727.179631 C887.209271,735.215892 900.205719,753.706305 900.205719,775.232202 C900.205719,802.419284 879.474379,824.764303 852.957874,827.321083 C871.418447,835.406163 884.315669,853.837421 884.315669,875.280987 C884.315669,904.183571 860.885496,927.613744 831.982912,927.613744 L668.846388,927.613744 C666.954042,927.86823 664.992539,927.997703 662.960487,927.997703 C638.234677,927.997703 610.89225,927.997703 575.871263,927.997703 C570.178189,927.997703 564.550144,927.642273 559,926.947847 Z" id="Combined-Shape" fill={ props.color }></path>
			</g>
		</svg>
	)
}

export default Card;
