import React from 'react';

export const Home = (props) => {

	return (
		<svg
		width={ props.size }
		height={ props.size }
		viewBox="0 0 1240 1240"
		xmlns="http://www.w3.org/2000/svg" >
			<g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<path d="M654.128213,908.355229 L883.128213,908.355229 L883.128213,567.355229 L907.601331,567.355229 L619.628213,336.233578 L331.655095,567.355229 L353.128213,567.355229 L353.128213,908.355229 L429.128213,908.355229 L429.128213,709.355229 C429.128213,678.979568 453.752551,654.355229 484.128213,654.355229 L599.128213,654.355229 C629.503874,654.355229 654.128213,678.979568 654.128213,709.355229 L654.128213,908.355229 Z M768.628213,638.355229 C821.371406,638.355229 864.128213,681.112035 864.128213,733.855229 C864.128213,786.598423 821.371406,829.355229 768.628213,829.355229 C715.885019,829.355229 673.128213,786.598423 673.128213,733.855229 C673.128213,681.112035 715.885019,638.355229 768.628213,638.355229 Z" id="Combined-Shape" stroke={ props.color } strokeWidth="70" fill={ props.color }></path>
			</g>
		</svg>
	)
}

export default Home;
