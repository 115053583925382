import React from 'react';

export const Lock = (props) => {

	return (
		<svg
		width={ props.size }
		height={ props.size }
		viewBox="0 0 1240 1240"
		xmlns="http://www.w3.org/2000/svg" >
		    <g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
					<path d="M423.5,563 L816.5,563 C842.181241,563 863,583.818759 863,609.5 L863,907.5 C863,933.181241 842.181241,954 816.5,954 L423.5,954 C397.818759,954 377,933.181241 377,907.5 L377,609.5 C377,583.818759 397.818759,563 423.5,563 Z M598,749.056471 L598,832 C598,844.150264 607.849736,854 620,854 C632.150264,854 642,844.150264 642,832 L642.000007,749.609948 C656.286351,741.936012 666,726.852378 666,709.5 C666,684.371044 645.628956,664 620.5,664 C595.371044,664 575,684.371044 575,709.5 C575,726.444413 584.26227,741.225507 598,749.056471 Z" id="Combined-Shape" fill={ props.color }></path>
					<path d="M499,608.220877 C499,552.991223 499,513.646151 499,490.185659 C499,376.196017 559.325022,322 619.650044,322 C679.975066,322 740.300088,364.013562 740.300088,490.185659 C740.300088,511.634194 740.300088,550.979267 740.300088,608.220877" id="Path-5" stroke={ props.color } strokeWidth="70"></path>
		    </g>
		</svg>
	)
}

export default Lock;
