import React from 'react';

export const Sync = (props) => {

	return (
		<svg
		width={ props.size }
		height={ props.size }
		viewBox="0 0 1240 1240"
		xmlns="http://www.w3.org/2000/svg" >
			<g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Group" transform="translate(320.000000, 203.000000)">
						<path d="M1.44950718e-12,527.701524 C165.685425,527.701524 300,393.386949 300,227.701524 C300,188.603168 292.520518,151.251738 278.914319,117" id="Oval" stroke={ props.color } strokeWidth="70" strokeLinecap="round" transform="translate(150.000000, 322.350762) rotate(180.000000) translate(-150.000000, -322.350762) "></path>
						<path d="M387.077709,52.1884181 L479.984734,175.270374 C483.977546,180.559997 482.926273,188.084897 477.636651,192.077709 C475.554195,193.649627 473.016153,194.5 470.407025,194.5 L284.592975,194.5 C277.965558,194.5 272.592975,189.127417 272.592975,182.5 C272.592975,179.890872 273.443348,177.35283 275.015266,175.270374 L367.922291,52.1884181 C371.915103,46.8987954 379.440003,45.8475226 384.729626,49.8403346 C385.617356,50.5104282 386.407616,51.3006873 387.077709,52.1884181 Z" id="Triangle" fill={ props.color } transform="translate(377.500000, 117.000000) rotate(90.000000) translate(-377.500000, -117.000000) "></path>
				</g>
				<g id="Group" transform="translate(692.500000, 773.000000) rotate(180.000000) translate(-692.500000, -773.000000) translate(465.000000, 509.000000)">
						<path d="M1.44950718e-12,527.701524 C165.685425,527.701524 300,393.386949 300,227.701524 C300,188.603168 292.520518,151.251738 278.914319,117" id="Oval" stroke={ props.color } strokeWidth="70" strokeLinecap="round" transform="translate(150.000000, 322.350762) rotate(180.000000) translate(-150.000000, -322.350762) "></path>
						<path d="M387.077709,52.1884181 L479.984734,175.270374 C483.977546,180.559997 482.926273,188.084897 477.636651,192.077709 C475.554195,193.649627 473.016153,194.5 470.407025,194.5 L284.592975,194.5 C277.965558,194.5 272.592975,189.127417 272.592975,182.5 C272.592975,179.890872 273.443348,177.35283 275.015266,175.270374 L367.922291,52.1884181 C371.915103,46.8987954 379.440003,45.8475226 384.729626,49.8403346 C385.617356,50.5104282 386.407616,51.3006873 387.077709,52.1884181 Z" id="Triangle" fill={ props.color } transform="translate(377.500000, 117.000000) rotate(90.000000) translate(-377.500000, -117.000000) "></path>
				</g>
			</g>
		</svg>
	)
}

export default Sync;
