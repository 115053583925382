import React from 'react';

export const Link = (props) => {

	return (
		<svg
		width={ props.size }
		height={ props.size }
		viewBox="0 0 1240 1240"
		xmlns="http://www.w3.org/2000/svg" >
			<g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Group" transform="translate(620.099332, 620.099332) rotate(45.000000) translate(-620.099332, -620.099332) translate(472.099332, 254.599332)" stroke={ props.color } strokeWidth="60">
            <path d="M295.075444,612.224215 C295.075444,607.229646 295.082358,514.305308 295.075444,509.498916 C294.888824,379.768944 289.665107,318.452579 143.447388,318.452579 C123.590572,318.452579 106.477192,319.672222 91.7292452,322.09439 C36.0431495,331.240145 14.0812003,357.530399 5.49446999,400.043709 C-1.01078717,432.251546 0.160648953,473.77059 0.160648953,524.200177 C0.160648953,655.097197 0.160648953,730.452579 147.580437,730.452579 C169.513922,730.452579 188.185905,729.065706 204.08137,726.232489" id="Oval" transform="translate(147.578517, 524.452579) scale(-1, -1) translate(-147.578517, -524.452579) "></path>
            <path d="M295.075444,294.026163 C295.075444,289.031595 295.082358,196.107257 295.075444,191.300864 C294.888824,61.5708926 289.665107,0.254527624 143.447388,0.254527624 C123.590572,0.254527624 106.477192,1.4741703 91.7292452,3.89633881 C36.0431495,13.0420936 14.0812003,39.3323478 5.49446999,81.8456576 C-1.01078717,114.053495 0.160648953,155.572538 0.160648953,206.002126 C0.160648953,336.899145 0.160648953,412.254528 147.580437,412.254528 C169.513922,412.254528 188.185905,410.867655 204.08137,408.034437" id="Oval"></path>
        </g>
			</g>
		</svg>
	)
}

export default Link;
