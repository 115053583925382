import React from 'react';

export const Cloud = (props) => {

	return (
		<svg
		width={ props.size }
		height={ props.size }
		viewBox="0 0 1240 1240"
		xmlns="http://www.w3.org/2000/svg" >
		<g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<path d="M383.25314,893.665684 C290.541466,888.331894 217,811.266389 217,716.980198 C217,643.319541 261.885625,580.169434 325.731093,553.522306 C325.238383,547.914642 324.986799,542.237596 324.986799,536.50165 C324.986799,430.738209 410.521837,345 516.034653,345 C585.541329,345 646.37859,382.206345 679.805784,437.83387 C700.637342,426.66468 724.44854,420.330033 749.739274,420.330033 C831.59316,420.330033 897.948845,486.685718 897.948845,568.539604 C897.948845,572.483823 897.794774,576.392056 897.492321,580.258614 C969.561359,595.1033 1023.74917,658.910822 1023.74917,735.374587 C1023.74917,821.80455 954.515253,892.064063 868.481223,893.719314 C867.968067,893.958711 867.395687,894.092409 866.792079,894.092409 L384.072607,894.092409 C383.733595,894.092409 383.433983,893.923712 383.25314,893.665684 Z" id="Combined-Shape" stroke={ props.color } strokeWidth="70"></path>
		</g>
		</svg>
	)
}

export default Cloud;
