import React from 'react';

export const CloudDownload = (props) => {

	return (
		<svg
		width={ props.size }
		height={ props.size }
		viewBox="0 0 1240 1240"
		xmlns="http://www.w3.org/2000/svg" >
		<path d="M647.805422,792 L647.805422,1026.78664 L780.26217,894.698685 C791.354606,883.818293 809.407004,883.818293 820.281942,894.916293 C831.15688,906.014293 830.939382,923.858136 819.629446,934.52092 L639.105472,1113.82978 C633.668002,1119.05237 626.708042,1121.88127 619.313084,1121.88127 C615.615605,1121.88127 611.918126,1121.22845 608.438146,1119.70519 C604.958166,1118.18194 602.130682,1116.22347 599.520696,1113.82978 L418.996722,934.52092 C407.904285,923.42292 407.904285,905.796685 418.779223,894.698685 C429.87166,883.818293 447.706559,883.600685 458.798996,894.481077 L591.255743,1026.78664 L591.255743,792 L404.319958,792 C397.802969,792.661276 391.190939,793 384.5,793 C277.080616,793 190,705.695526 190,598 C190,512.754211 244.559708,440.284284 320.579424,413.775005 C320.195415,408.566978 320,403.306364 320,398 C320,281.467918 414.24406,187 530.5,187 C608.638737,187 676.833509,229.676305 713.152002,293.044508 C734.473934,278.502293 760.244058,270 788,270 C861.453872,270 921,329.546128 921,403 C921,418.208301 918.447384,432.820407 913.747219,446.431248 C991.221523,464.237511 1049,533.621312 1049,616.5 C1049,712.873689 970.873689,791 874.5,791 C874.333279,791 874.166612,790.999766 874,790.999299 L874,792 L647.805422,792 Z M647.805422,792 L647.805422,523.459708 C647.805422,508.009552 635.190494,495.388297 619.530583,495.388297 C603.870672,495.388297 591.255743,508.009552 591.255743,523.459708 L591.255743,791.987911 L647.805422,792 Z" id="Combined-Shape" fill={ props.color }></path>
		<g id="Group" transform="translate(410.000000, 791.000000)">
				<g id="_ionicons_svg_ios-arrow-round-back" transform="translate(0.500000, 0.000000)">
						<g id="Group-2" transform="translate(209.000000, 165.500000) scale(1, -1) translate(-209.000000, -165.500000) "></g>
				</g>
		</g>
		</svg>
	)
}

export default CloudDownload;
