import React from 'react';

export const LineChart = (props) => {

	return (
		<svg
		width={ props.size }
		height={ props.size }
		viewBox="0 0 1240 1240"
		xmlns="http://www.w3.org/2000/svg" >
		    <g id="Artboard" stroke="none" strokeWidth="1" fill={ props.color } fillRule="evenodd">
					<path d="M280,871 L995,871 C1014.32997,871 1030,886.670034 1030,906 C1030,925.329966 1014.32997,941 995,941 L245,941 C225.670034,941 210,925.329966 210,906 L210,295 C210,275.670034 225.670034,260 245,260 C264.329966,260 280,275.670034 280,295 L280,871 Z M536.178132,513.675861 C545.443201,513.594836 554.722445,517.170385 561.666829,524.361505 L661.172259,627.402395 L890.103843,406.325735 C904.008657,392.898012 926.166056,393.284771 939.593779,407.189585 C953.021501,421.094399 952.634743,443.251798 938.729928,456.679521 L684.802979,701.893926 C677.964226,708.498033 669.12923,711.760512 660.318359,711.716629 C651.097399,711.762555 641.873496,708.186898 634.962233,701.030076 L535.328855,597.856692 L376.036031,751.683984 C362.131217,765.111707 339.973818,764.724948 326.546095,750.820134 C313.118372,736.91532 313.505131,714.757921 327.409945,701.330198 L511.560934,523.497655 C518.433988,516.860424 527.32336,513.598426 536.178129,513.675861 Z" id="Combined-Shape" fill={ props.color }></path>
		    </g>
		</svg>
	)
}

export default LineChart;
