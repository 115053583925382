import React from 'react';

export const Card = (props) => {

	return (
		<svg
		width={ props.size }
		height={ props.size }
		viewBox="0 0 1240 1240"
		xmlns="http://www.w3.org/2000/svg" >
			<g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<path d="M1042.5,471.5 L1042.5,427 C1042.5,402.975613 1023.02439,383.5 999,383.5 L242,383.5 C217.975613,383.5 198.5,402.975613 198.5,427 L198.5,471.5 L1042.5,471.5 Z M1042.5,560.5 L198.5,560.5 L198.5,814 C198.5,838.024387 217.975613,857.5 242,857.5 L999,857.5 C1023.02439,857.5 1042.5,838.024387 1042.5,814 L1042.5,560.5 Z M284.5,751.5 L692.147059,751.5 C707.058747,751.5 719.147059,763.588312 719.147059,778.5 C719.147059,793.411688 707.058747,805.5 692.147059,805.5 L284.5,805.5 C269.588312,805.5 257.5,793.411688 257.5,778.5 C257.5,763.588312 269.588312,751.5 284.5,751.5 Z M804.5,751.5 C819.411688,751.5 831.5,763.588312 831.5,778.5 C831.5,793.411688 819.411688,805.5 804.5,805.5 L784.470588,805.5 C769.5589,805.5 757.470588,793.411688 757.470588,778.5 C757.470588,763.588312 769.5589,751.5 784.470588,751.5 L804.5,751.5 Z" id="Combined-Shape" fill={ props.color }></path>
			</g>
		</svg>
	)
}

export default Card;
