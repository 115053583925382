// This is the default colors
export const defaultColor = "#0019d8";
export const defaultTextColor = "#ffffff";

export const primaryColor = "#006eff";
export const primaryTextColor = "#ffffff";

export const successColor = "#23d160";
export const successTextColor = "#ffffff";

export const dangerColor = "#ff2a2a";
export const dangerTextColor = "#ffffff";


const defaultColorSet = {
	defaultColor,
	defaultTextColor,

	primaryColor,
	primaryTextColor,

	successColor,
	successTextColor,

	dangerColor,
	dangerTextColor,
}

export default defaultColorSet;
